export default {

  UPDATE_AUTHENTICATION_STATUS: "UPDATE_AUTHENTICATION_STATUS",
  UPDATE_AUTHENTICATION_STATUS_BEGIN: "UPDATE_AUTHENTICATION_STATUS_BEGIN",
  UPDATE_AUTHENTICATION_STATUS_SUCCESS: "UPDATE_AUTHENTICATION_STATUS_SUCCESS",
  UPDATE_AUTHENTICATION_STATUS_FAILURE: "UPDATE_AUTHENTICATION_STATUS_FAILURE",

  LOG_OUT: "LOG_OUT",
  LOG_OUT_BEGIN: "LOG_OUT_BEGIN",
  LOG_OUT_SUCCESS: "LOG_OUT_SUCCESS",
  LOG_OUT_FAILURE: "LOG_OUT_FAILURE",

  SEARCH_FACET_CHANGE: "SEARCH_FACET_CHANGE",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  SEARCH_RESET: "SEARCH_RESET",
  SEARCH_OFFSET_CHANGE: "SEARCH_OFFSET_CHANGE",

  FETCH_SPECIMENS: "FETCH_SPECIMENS",
  FETCH_SPECIMENS_BEGIN: "FETCH_SPECIMENS_BEGIN",
  FETCH_SPECIMENS_SUCCESS: "FETCH_SPECIMENS_SUCCESS",
  FETCH_SPECIMENS_FAILURE: "FETCH_SPECIMENS_FAILURE",

  WORKBENCH_ADD_SPECIMEN: "WORKBENCH_ADD_SPECIMEN",
  WORKBENCH_REMOVE_SPECIMEN: "WORKBENCH_REMOVE_SPECIMEN",

  SHOW_SPECIMENS: "SHOW_SPECIMENS",
  SHOW_SEARCH: "SHOW_SEARCH",
  SHOW_SPECIMEN_MODAL: "SHOW_SPECIMEN_MODAL",

  // tags

  FETCH_TAGS: "FETCH_TAGS",
  FETCH_TAGS_BEGIN: "FETCH_TAGS_BEGIN",
  FETCH_TAGS_SUCCESS: "FETCH_TAGS_SUCCESS",
  FETCH_TAGS_FAILURE: "FETCH_TAGS_FAILURE",

  SAVE_TAG: "SAVE_TAG",
  SAVE_TAG_BEGIN: "SAVE_TAG_BEGIN",
  SAVE_TAG_SUCCESS: "SAVE_TAG_SUCCESS",
  SAVE_TAG_FAILURE: "SAVE_TAG_FAILURE",

  DELETE_TAG: "DELETE_TAG",
  DELETE_TAG_BEGIN: "DELETE_TAG_BEGIN",
  DELETE_TAG_SUCCESS: "DELETE_TAG_SUCCESS",
  DELETE_TAG_FAILURE: "DELETE_TAG_FAILURE",

  // Determinations

  SAVE_DET: "SAVE_DET",
  SAVE_DET_BEGIN: "SAVE_DET_BEGIN",
  SAVE_DET_SUCCESS: "SAVE_DET_SUCCESS",
  SAVE_DET_FAILURE: "SAVE_DET_FAILURE",

  DELETE_DET: "DELETE_DET",
  DELETE_DET_BEGIN: "DELETE_DET_BEGIN",
  DELETE_DET_SUCCESS: "DELETE_DET_SUCCESS",
  DELETE_DET_FAILURE: "DELETE_DET_FAILURE",

  FETCH_DETS: "FETCH_DETS",
  FETCH_DETS_BEGIN: "FETCH_DETS_BEGIN",
  FETCH_DETS_SUCCESS: "FETCH_DETS_SUCCESS",
  FETCH_DETS_FAILURE: "FETCH_DETS_FAILURE",

  // Compare
  ADD_COMPARE_SPECIMENS: "ADD_COMPARE_SPECIMENS"

};
