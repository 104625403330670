import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";


class About extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
        return (
            <Container style={{ paddingTop: "2em" }}>

              <a href="https://iiif.io"><img src="images/iiif_logo.png" style={{float: 'right', width: '20%', marginLeft: '1em', marginBottom: '1em'}} /></a>

            <h3>What is Herbaria Mundi?</h3>

            <p>Herbaria Mundi is primarily a technology demonstrator for the adoption of <a href="https://iiif.io">IIIF</a> (International Image Interoperability Framework) by herbaria and the wider natural history community.</p>

<h3>Walk Through of Features, October 2020</h3>

<p>This video will give you a quick (5 minute) walk through</p>

<iframe width="800" height="450" src="https://www.youtube.com/embed/_jpRD-DcO_0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <p>{' '}</p>

            <h3>What problem is it trying to solve?</h3>

            <p>Herbaria around the world have been making high resolution digital images of their specimens for well over a decade now. They have been sharing these specimen images in two principle ways:</p>
            <ol>
              <li>Through their own on-line catalogues. These sometimes present zoomable versions of the images, sometimes
                 present reduced versions and sometimes allow downloads. Each herbarium has tended to come up with its own solution although combined approaches have emerged (e.g. <a href="https://www.jacq.org/">JACQ</a>).
</li>
              <li>Uploading files to aggregator projects. These have been reduced resolution files (e.g. <a href="https://www.gbif.org/">GBIF</a> and <a href="https://www.europeana.eu/">Europeana</a>) 
              or high resolution but behind a paywall (e.g. <a href="https://plants.jstor.org/">JSTOR</a>).
</li>
            </ol>

            <p>Maintaining on-line catalogues is costly and often outside the core skills of curators. Because each herbarium has implemented their catalogues slightly differently the user of specimens has to navigate a plethora of different layouts and interactions to do their work.</p>

            <p>When dealing with aggregators curators are ceding control of the specimen to multiple other parties and are no longer full custodians of their collections. This has the potential to further erode support for the physical collection.</p>

            <h3>How does IIIF help?</h3>

            <p>Implementation of <a href="https://iiif.io">IIIF</a> offers another way. Curators continue to manage and host their digital specimens (possibly having a third party do the techie bit) but instead of distributing jpeg files directly to users they publish a manifest file for each specimen that describes the availability of images of that specimen. Client software can then request versions of these images to display and analyse.  The manifests can contain multiple views of a specimen along with additional annotations. The manifests can be updated as new information about the specimen emerges.</p>

            <p>Using <a href="https://iiif.io">IIIF</a> enables indexing and aggregating projects to up their game and become more fully featured integrators of digital specimens. They can offer both views across multiple collections and the tools that have been implemented by some institutional on-line catalogues.</p>

            <h3>How does Herbaria Mundi demonstrate this?</h3>

            <p>Herbaria Mundi is taking faltering, baby steps that we hope will show the potential of adoption of <a href="https://iiif.io">IIIF</a> in our community. Its goal is to get people a little bit excited about the possibilities and to start a conversation. It may grow into something bigger or be replaced. The key point is that this system runs on top of institutions implementing <a href="https://iiif.io">IIIF</a> and CETAF IDs. There should be nothing to stop someone else building a better system that uses the same IIIF endpoints or to stop existing providers implementing this functionality in their portals.</p>

            <h3>Can you give me a concrete example of a real world application?</h3>

            <p>Here is a video we made last year about the problems of identifying plot voucher specimens in the tropics. Researchers would be able to photograph their voucher specimens and upload the images to Zenodo (where they will be given permanent DOIs) a service then presents these specimens in Herbaria Mundi where they can be identified against reference material in multiple herbaria as well as viewed and annotated by experts around the world. This enables timely identification of specimens, knowledge sharing and open science. It also circumvents issues of moving specimens between jurisdictions and storing large numbers of suboptimal voucher specimens in conventional herbaria. Note: The Herbaria Mundi interface has changed a lot since this video was made but the principles remain the same.</p>

            <center>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/_4DuNBVsWW8"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </center>
            <p>&nbsp;</p>
            <p>Other examples include taxonomists building sets of specimens for taxon delimitation, identifying duplicates, geolocation of specimens etc. </p>

            <h3>What specimens are in Herbaria Mundi?</h3>
            <p>Currently Herbaria Mundi contains just over six hundred thousand specimens from Royal Botanic Garden Edinburgh and Botanic Garden and Botanical Museum Berlin as well as a few thousand that are hosted in Zenodo rather than an institutional herbarium. We are talking to other IIIF adopters and expect this number will increase soon.</p>

            <h3>How do I get my specimens into Herbaria Mundi?</h3>

            <p>If you have an institutional collection please contact me (<a href="mailto:rhyam@rbge.org.uk">Roger Hyam</a>). I can help you with deployment of 
            <a href="https://cetafidentifiers.biowikifarm.net/wiki/Main_Page">CETAF IDs</a> and <a href="https://iiif.io">IIIF</a> services on your collection. If you are a researcher with significant numbers of specimen images from different herbaria then we can talk about getting these into Zenodo so they are archived for posterity and made available through Herbaria Mundi.</p>

            <h3>Herbaria Mundi sucks! How can I let people know?</h3>

            <p>That’s great! Please email me (<a href="mailto:rhyam@rbge.org.uk">Roger Hyam</a>) and tell me what should do / not do that would ould make it right for you. I’d really need people to be saying what they want. Praise is good too.</p>

            <h3>Herbaria Mundi rocks! How can I let people know?</h3>
            <p>Encourage people to use it and suggest improvements. See “Herbaria Mundi sucks!” above.</p>

            <p>&nbsp;</p>
            <hr/>
            <p>&nbsp;</p>
            <h2>Support</h2>
            <p>
              Initial development work was carried out as part of a project
              funded by the British Council and Newton Fund. It is now being
              added to as part of Synthesys+.</p>

            <h3>About the British Council</h3>
            <p>
              <a href="https://www.britishcouncil.org/">
                <img
                  src="images/British_Council.png"
                  alt="British Council Logo"
                  style={{ float: "right", width: "15rem" }}
                />
              </a>
                The British Council is the UK’s international organisation for
                cultural relations and educational opportunities. We were
                founded in 1934 and incorporated by Royal Charter in 1940.
              </p>
            <p>
              For more information visit:{" "}
              <a href="https://www.britishcouncil.org/">
                www.britishcouncil.org
                </a>
            </p>

            <h3>About the Newton Fund</h3>
            <p>
              <a href="https://www.newtonfund.ac.uk">
                <img
                  src="images/Newton-Fund-Master-rgb-small.JPG"
                  alt="Newton Fund Logo"
                  style={{ float: "right", width: "15rem" }}
                />
              </a>
                The Newton Fund is now a £735 million fund which, through
                science and innovation partnerships, promotes the economic
                development and social welfare of partnering countries. It aims
                to strengthen science and innovation capacity and unlock further
                funding through which the UK and partner countries will build
                strong, sustainable and systemic relationships. It is delivered
                through 7 UK delivery partners in collaboration with the partner
                countries. Activities are in three broad activities:{" "}
            </p>
            <div>
              <ul>
                <li>
                  <strong>People:</strong> increasing capacity for science and
                    innovation in partner countries.
                  </li>
                <li>
                  <strong>Research:</strong> collaborations on development
                    topics.
                  </li>
                <li>
                  <strong>Translation:</strong> creating collaborative
                    solutions to development challenges and strengthening
                    innovation systems.
                  </li>
              </ul>
              <p>
                For more information visit:{" "}
                <a href="https://www.newtonfund.ac.uk">
                  www.newtonfund.ac.uk
                  </a>{" "}
                  and follow via Twitter:{" "}
                <a href="https://twitter.com/NewtonFund">@NewtonFund</a>
              </p>
            </div>
          </Container>
        );
    }


}
const mapStateToProps = state => {
    return {
       
    };
};
export default connect(mapStateToProps, {})(About);